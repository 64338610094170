import $ from "jquery";

export const kill = function(modal): void {
  if(modal == $(modal)) {
    modal.addClass('gone');
    modal.children().removeClass('show');
  }
  else {
    $(modal).addClass('gone');
  }
};

export const reveal = function(modal): void {
  const $html = $('html');
  $(modal).removeClass('gone').addClass('show');

  $html.addClass('modal-showing');

  if ($(modal).hasClass('modal__wrapper--menu')) {
    $.publish('FG.modalmenu.show');
    $html.addClass('modal-menu-showing');
  }
  // For Welcome screen. Remove after it's gone.
  /* if($welcomeScreen.hasClass('show')){
     $welcomeScreen.addClass('stash');
     $('.siteheader__menu').one('click', function(){
       $welcomeScreen.removeClass('stash');
     });
   }*/
};

export const vanish = function(modal): void {
  const $html = $('html');

  if(modal == $(modal)) {
    modal.removeClass('show');
  }
  else {
    $(modal).removeClass('show');
  }
  if($(modal).hasClass('modal__wrapper--beta')) {
    //$html.removeClass('modal-showing');
  }
  else {
    $html.removeClass('modal-showing');
    $html.removeClass('modal-menu-showing');
  }

  setTimeout(function(){
    kill(modal);
  }, 200);
};
