export default () => {
  const url = new URL(window.location.href);
  const templates = document.querySelectorAll("[data-cloudflare-a-b-test-id]");
  let group = 'control';
  let testId;
  if(url.searchParams.get('cloudflare-a-b-test-group') === 'test') {
    group = 'test';
  }

  if(url.searchParams.has('cloudflare-a-b-test-id')) {
    testId = url.searchParams.get('cloudflare-a-b-test-id');
  }

  [].forEach.call(templates, (item: HTMLTemplateElement) => {
    const clone = item.content.cloneNode(true);
    if(testId) {
      if(item.getAttribute('data-cloudflare-a-b-test-id') === testId && item.getAttribute('data-cloudflare-a-b-test-group') === group) {
        item.after(clone);
      }
    } else {
      if (item.getAttribute('data-cloudflare-a-b-test-group') === group) {
        item.after(clone);
      }
    }
    item.remove();
  });

}
