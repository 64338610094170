export default () => {
  if('IntersectionObserver' in window) {
    const $siteheaderScrolled: HTMLElement =  document.querySelector('.siteheader--scrolled'),
      $siteheaderMain: HTMLElement = document.querySelector('.siteheader--main'),
      $body: HTMLElement = document.querySelector('body'),
      $allAccessModal: HTMLElement = document.querySelector( '.modal__wrapper--all-access' );

    // handle special case where all access modal also shows the scrolled header
    if( $allAccessModal && $allAccessModal.classList.contains('show') ) {
      return;
    }

    if($siteheaderScrolled && $siteheaderMain && $body) {
      const navObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // in view
            $body.classList.remove('past-subnav-waypoint');
            $siteheaderScrolled.classList.remove('scrolled');
            $siteheaderScrolled.setAttribute('aria-hidden', 'true');
            $siteheaderMain.classList.remove('offscreen');
            $siteheaderMain.classList.remove('scroll-waypoint');

          } else {
            // out of view
            $body.classList.add('past-subnav-waypoint');
            $siteheaderScrolled.classList.add('scrolled');
            $siteheaderScrolled.setAttribute('aria-hidden', 'false');
            $siteheaderMain.classList.add('scroll-waypoint');
          }
        });
      }, {
        rootMargin: '0px 0px 200px 0px'
      });
      const navWaypoint: HTMLElement = document.getElementById('sticky-header-landmark');

      if(navWaypoint) {
        navObserver.observe(navWaypoint);
      }
    }
  }
}
