// Comments Trigger Handler \\
import $ from "jquery";

export default () => {
  const toggle = (e) => {
    const $commentsBlock = $('.comments');
    if ($commentsBlock.hasClass('open')) {
      hide();
      $commentsBlock.slideUp(250);
    } else {
      show();
      $commentsBlock.slideDown(250);
    }
  };

  const hide = () => {
    const $commentsBlock = $('.comments');
    $commentsBlock.removeClass('open');
  };

  const show = () => {
    var $commentsBlock = $('.comments');
    $commentsBlock.addClass('open');
    $.publish('fww.comments.show');
  };

  document.addEventListener('DOMContentLoaded', () => {
    if(document.querySelector('.article__comments-trigger')) {
      $('body').on('click', '.article__comments-trigger', toggle);
    }
  });
}
