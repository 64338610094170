import $ from "jquery";

export default function($class) {
  const $images: JQuery = $($class + ' img');
  $images.each(function () {
    const $this: JQuery = $(this);
    if (!$this.data('nopinbutton') && $this.width() >= 250) {
      let theParent;
      if ($this.parent().hasClass('hover-wrap')) {
        theParent = $this.parent();
      }
      if (theParent) {
        if (theParent.parent().hasClass('wp-caption')) {
          theParent = theParent.parent();
        }
        if ($this.width() > 0) {
          theParent.css('max-width', $this.width() + 'px');
        }
        if ($this.hasClass('alignright') || $this.hasClass('alignleft')) {
          if ($this.hasClass('alignright')) {
            $this.removeClass('alignright');
            theParent.addClass('alignright');
          }
          if ($this.hasClass('alignleft')) {
            $this.removeClass('alignleft');
            theParent.addClass('alignleft');
          }
        } else if ($this.css('float')) {
          $this.parent().css('float', $this.css('float'));
          if ($this.css('float') === 'right') {
            theParent.css('margin-left', 20);
          }
          if ($this.css('float') === 'left') {
            theParent.css('margin-right', 20);
          }
          if ($this.css('float') === 'none' || $this.css('float') === '') {
            $this.parent().css('clear', 'both');
          }
        }
        if ($this.hasClass('aligncenter')) {
          theParent.addClass('aligncenter');
        } else if ($this.attr('align') === 'aligncenter') {
          theParent.addClass('aligncenter');
        }

        // let the parent item control formatting
        $this.css({
          'float': 'none',
          'margin-left': 0,
          'margin-right': 0,
          'max-width': '100%',
          'width': 'auto'
        });
      }
    }
  });
}
