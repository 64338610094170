import search from './search/search';
import region from '../region';
import userTimings from '../user-timings';
import prebid from './analytics/prebid';
import initArticleFeatures from './article/init-article-features';
import initMembershipsFeatures from './memberships/init-memberships-features';
import setLinkTarget from './behavior/set-link-target';
import menuRollup from './menu/menu-rollup';
import toggleComments from './comments/toggle-comments';
import setHeaderWaypoint from './set-header-waypoint/set-header-waypoint';
import expandableMenu from './expandable-menu/expandable-menu';
import emailOnlyForms from './form-submit/email-only-forms';
import newsletterSplash from './form-submit/newsletter-splash';
import partnerOptin from './form-submit/partner-optin';
import eventRegistration from './form-submit/event-registration';
import recordSelectorUsage from './analytics/record-selector-usage';
import emblaCarousel from "./carousel/embla-carousel";
import accountPanel from "./account-panel/account-panel";
import imageZoom from "./article-gallery/image-zoom";
import imageCarousel from "./article-gallery/image-carousel";
import recentQuestions from './recent-questions/recent-questions';
import renderABTemplate from "./a-b-test/render-a-b-template";

search();
region();
userTimings();
initArticleFeatures();
initMembershipsFeatures();
setLinkTarget();
menuRollup();
expandableMenu();
emblaCarousel();
toggleComments();
emailOnlyForms();
newsletterSplash();
partnerOptin();
eventRegistration();
recordSelectorUsage();
accountPanel();
imageZoom('.article__wrap');
imageCarousel()
recentQuestions();
renderABTemplate();

document.addEventListener('DOMContentLoaded', () => {
  prebid();
  setHeaderWaypoint();
});
