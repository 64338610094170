import $ from "jquery";

export default function($class: string): void {
  const $images: JQuery = $($class +' img');

  $images.each(function() {
    const $this: JQuery = $(this);
    if(!$this.data('nopinbutton') && $this.width() >= 250 && $this.attr('width') && $this.attr('height')){
      if(!$this.parent().hasClass('hover-wrap')){
        const hoverWrap: string = `<div class='hover-wrap'></div>`;
        $this.wrap(hoverWrap).parent();
      }
    }
  });
}
