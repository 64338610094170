export default () => {
  window.addEventListener('DOMContentLoaded', () => {
    const forms = document.querySelectorAll('.email-subscribe-form');
    let i = 0;

    [].forEach.call(forms, (form) => {
      console.log('each form');

      function formUpdate(msg) {
        form.innerHTML = msg;
      }
      function createError(name, msg) {
        removeError(name);
        let l = document.createElement("label");
        l.classList.add("error");
        l.setAttribute("for", name);
        l.setAttribute("id",name+"-error");
        l.setAttribute("name","inline-error");
        l.innerHTML = msg;
        return l
      }
      function removeError(name) {
        let error = form.querySelector('#'+name+"-error");
        if (error) {
          error.remove();
        }
      }
      function removeErrors(){
        let errors = form.querySelectorAll('.inline-error');
        [].forEach.call(errors, (error) => {
          error.remove();
        });
      }

      i++;

      const eleEmail = form.querySelector('input[type=email]') as HTMLInputElement | null;

      eleEmail.setAttribute("id","email-subscribe-"+i);

      eleEmail.addEventListener('focus', () => {
        removeError(eleEmail.id);
      });

      form.addEventListener('submit', (e) => {
        e.preventDefault();

        removeErrors();
        // Temporary email validation
        if ( !eleEmail.value.match(/(.+)@(.+){2,}\.(.+){2,}/) ) {
          eleEmail.parentElement.parentElement.append(createError(eleEmail.id,"Email is required and must have @ and ."));
          return false;
        }

        form.querySelector('.email_subscribe__form_submit').parentElement.classList.add('loading');
        form.querySelector('.email_subscribe__form_textfield').parentElement.style.display = "none";

        import('./reCaptchaValidate').then(({default: validate}) => {
          validate(form.dataset.placement)
          .then((result) => {
            import('./processEmailForm').then(({default: processEmailForm}) => {
              processEmailForm(form)
              .then((result) => {
                formUpdate('<p class="response thankyou">Thanks for signing up!</p>');
              })
              .catch((result) => {
                formUpdate('<p class="response error">Sorry, there was an error submitting your email.  Please contact customer service to sign up.</p>');
                console.log(result);
              });
            })
          })
          .catch((result) => {
            formUpdate('<p class="response error">Spammer Detected!</p>');
            console.log(result);
          });
        });
      });
    });
  });
}
