export default (form: any) => {

  return new Promise((resolve, reject) => {

    var $form = $(form);
    const email = $form.find('input[type=email]').val();
    const fname = $form.find('input[id=firstname]').val();
    const lname = $form.find('input[id=lastname]').val();
    const source = $form.data('source');
    const data_product = $form.data('email_sub_products');
    const optins = $('.formfield__checkbox .real_checkbox');
    const email_subscribe_nonce = $form.data('email_subscribe_nonce');

    let products = [];

    let formData = {
      action: 'omeda_email_subscribe'
    };
    if (email) {
      formData['email'] = email;
    } else {
      reject("email is blank");
    }
    if (fname) {
      formData['firstname'] = fname;
    }
    if (lname) {
      formData['lastname'] = lname;
    }
    if (source) {
      formData['source'] = source;
    }

    if (email_subscribe_nonce) {
      formData['email_subscribe_nonce'] = email_subscribe_nonce;
    }

    if (data_product) {
      var i;
      let arrProd =String(data_product).split('|');
      for (i = 0; i < arrProd.length; i++) {
        products.push(arrProd[i]);
      }
    }

    optins.each(function() {
      if (! $(this).hasClass('partner')  && ( $(this).closest('.formfield__checkbox').hasClass('hide') || $(this).is(":checked") )) {
        products.push($(this).val());
      }
    });

    formData['email_sub_products'] = products.join( "|" );

    $.ajax({
      type: 'POST',
      url: '/wp/wp-admin/admin-ajax.php',
      dataType: 'json',
      data: formData,
      error: function (result: string) {
        reject(result);
      },
      success: function(result: { success: boolean; error: string; response: any }){
        if (result.success) {
          if (products.indexOf(FG.email_lists.daily) > -1) {
            import('../user-data/user-set').then(({default: userSet}) => {
            userSet('fg_gpod_newsletter_signed_up', 'true')});
          }
          if (products.indexOf(FG.email_lists.eletter) > -1) {
            import('../user-data/user-set').then(({default: userSet}) => {
            userSet('fg_newsletter_signed_up', 'true')});
          }
          if (products.indexOf(FG.email_lists.promo) > -1) {
            import('../user-data/user-set').then(({default: userSet}) => {
            userSet('fg_offer_newsletter_signed_up', 'true')});
          }
          $.publish('FG.newsletter.signup', $(form).data('placement'));
          resolve(result);
        } else {
          reject (result);
        }
      }
    });
  });
}
