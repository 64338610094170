import $ from "jquery";

const calcWidth = () => {
  var navwidth = 0;
  var morewidth = $('#main-roll-up-list .more').outerWidth(true);
  $('#main-roll-up-list > li:not(.more)').each(function() {
    navwidth += $(this).outerWidth( true );
  });
  // Components that take away from available space
  var searchwidth = $('.siteheader__nav__search .svg-search').outerWidth(true) * 2;
  var searchlinkwidth = $('.siteheader__search-bar__search-link').outerWidth(true);
  // Available space
  var availablespace = $('.siteheader__nav').outerWidth(true) - morewidth - searchwidth - searchlinkwidth - 40;

  if (navwidth > availablespace) {
    if($('.siteheader__nav__list > li').not('.more').length) {
      var lastItem = $('#main-roll-up-list > li:not(.more)').last();
      lastItem.attr('data-width', lastItem.outerWidth(true));
      lastItem.prependTo($('#main-roll-up-list .more > ul'));
      calcWidth();
    }
  } else {
    var firstMoreElement = $('#main-roll-up-list li.more li').first();
    if (navwidth + firstMoreElement.data('width') < availablespace) {
      firstMoreElement.insertBefore($('#main-roll-up-list .more'));
    }
  }

  if ($('#main-roll-up-list .more li').length > 0) {
    $('#main-roll-up-list .more').css('display','inline-block');
  } else {
    $('#main-roll-up-list .more').css('display','none');
  }
}

const calcScrolledWidth = () => {
  var navwidth = 0;
  var morewidth = $('#scrolled-roll-up-list .more').outerWidth(true);
  $('#scrolled-roll-up-list > li:not(.more)').each(function() {
    navwidth += $(this).outerWidth( true );
  });
  // Components that take away from available space
  var searchwidth = $('.siteheader--scrolled__nav__search .svg-search').outerWidth(true);
  var subscribewidth = $('.siteheader--scrolled__nav__subscribe').outerWidth(true);
  var logowidth = $('.siteheader--scrolled__logo').outerWidth(true);
  var searchlinkwidth = $('.siteheader--scrolled__search-bar__search-link').outerWidth(true);
  // Avaiable space
  var availablespace = function(){
    var space;
    if(FG.user.level == "member") {
      space = $('.siteheader--scrolled__nav').outerWidth(true) - morewidth - logowidth - searchwidth - searchlinkwidth - 150 ;
    }
    else {
      space = $('.siteheader--scrolled__nav').outerWidth(true) - morewidth - logowidth -  subscribewidth - searchwidth - searchlinkwidth - 20 ;
    }
    return space;
  }

  if (navwidth > availablespace()) {
    var lastItem = $('#scrolled-roll-up-list > li:not(.more)').last();
    lastItem.attr('data-width', lastItem.outerWidth(true));
    lastItem.prependTo($('#scrolled-roll-up-list .more > ul'));
    calcScrolledWidth();
  } else {
    var firstMoreElement = $('#scrolled-roll-up-list li.more li').first();
    if (navwidth + firstMoreElement.data('width') < availablespace) {
      firstMoreElement.insertBefore($('#scrolled-roll-up-list .more'));
    }
  }

  if ($('#scrolled-roll-up-list .more li').length > 0) {
    $('#scrolled-roll-up-list .more').css('display','inline-block');
  } else {
    $('#scrolled-roll-up-list .more').css('display','none');
  }
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    const windowWidth = window.matchMedia('(min-width: 701px)');

    if(windowWidth.matches) {
      calcScrolledWidth();
      calcWidth();
    }
  });
}
