import $ from 'jquery';
import getURLParams from './src/helpers/getURLParams';
import { reveal } from './src/modal-control/modal-control';
import userSet from './src/user-data/user-set';

export default () => {
  var router = new Navigo(location.protocol + "//" + location.hostname, false);

  var getParams = function (query) {
    var params = {};
    if (typeof URLSearchParams === 'function') {
      var urlParams = new URLSearchParams(query);
      params.setDefault = urlParams.get('set_default_region');
    } else {
      params.setDefault = getURLParams(query, 'set_default_region');
    }
    return params;
  };

  $(function () {
    var $html = $('html');

    var handleRegionChange = function () {

      var $regionToggle = $('.my-region__toggle');

      var $regionLink = $('.my-region__toggle__option-list__item a');

      $regionToggle.on('click', '.my-region__toggle__control', function (e) {
        e.preventDefault();
        $regionToggle.toggleClass('is-visible');
      });

      $regionLink.on( 'click', function () {
        var selected = $(this).attr('href');
        var region = selected.replace('/region/', '');
        userSet('fg_user_region', region, 365);
      });

    };

    router
      .on('/region/*', function (params, query) {

        var queryObj = getParams(query);

        if (queryObj.setDefault) {
          var region = window.location.pathname.replace('/region/', '');
          userSet('fg_user_region', region, 365);
        }

        // Gated Content
        if (FG.user.level !== 'member') {
          reveal('.modal__wrapper--all-access');
          $html.addClass('locked');
          $('.siteheader').hide();
          $('.siteheader--scrolled').addClass('scrolled').css({
            'z-index': 100000002 // > than the meter paywall overlay
          });
          $('body').css({'padding-top': '120px'});
        }

      }).resolve();

    handleRegionChange();
  });
}
