//FG-516
// Carousel Modal
import $ from "jquery";
import { vanish, reveal, kill } from '../modal-control/modal-control';
//import pinterestShares from '../analytics/pinterest-shares';

export default function () {
  window.addEventListener('load', () => {
    const $body = $('body');
    const $articleBodyCarousel = $('.article__body .owl-carousel_article');
    const $articleModal = $('.article__wrap .modal__wrapper');
    const $articleModalCarousel = $('.article__body .owl-carousel-modal');

    if(typeof $.fn.owlCarousel === 'function') {
      var carouselModal = {
        init: function () {
          $articleModalCarousel.each(function () {
            var $this = $(this);
            $this.css('display', 'none');
            $this.detach().appendTo('.modal__carousel');
          });
        }
      };
      carouselModal.init();
    }

    //pinterestShares();

    if(typeof $.fn.owlCarousel === 'function') {
      // Article Image Carousel Overlay
      var imageModal = {
        init: function () {
          const $images = $('.article__body img:not(.pin-it-button, .main-image, .tcc-product-image)');
          const $inPage = $('<div class="owl-carousel owl-carousel-modal off"></div>');
          let $carousel_count = 0;
          $images.each(function () {
            const $this = $(this);
            const $href = $this.closest('a').attr('href');

            // don't allow carousel for tiny images
            if ($this.width() < 250) {
              return;
            }

            if (typeof ($href) != "undefined") {
              //if linked image isn't to itself, we don't want any modal treatment
              if ($href.indexOf('.jpg') !== -1 || $href.indexOf('.png') !== -1 || $href.indexOf('.jpeg') !== -1) {
                $this.attr('data-in-page-carousel-id', $carousel_count);
                $carousel_count++;
                const $theSlide = imageModal.carouselImage($this, true);
                $inPage.append($theSlide);
              }
            } else {
              $this.attr('data-in-page-carousel-id', $carousel_count);
              $carousel_count++;
              const $theSlide = imageModal.carouselImage($this, false);
              $inPage.append($theSlide);
            }
          });
          if ($inPage.children().length > 0) {
            $inPage.appendTo('.modal__carousel');
          }
        },
        getCaption: function (e) {
          return $(e.target).closest('figure').find('figcaption').clone();
        },
        image: $('<img/>'),
        carouselImage: function (e, linked) {
          const $this = e;
          const $carousel_id = $this.data('in-page-carousel-id');
          let $img_href, $img_link, $carouselTrigger, $img_alt;
          if (linked) {
            $img_link = $this.closest('a');
            $img_href = $img_link.attr('href');
            $img_link.contents().unwrap();
          } else {
            $img_href = $this.attr('src');
          }
          $img_alt = $this.attr('alt');

          $carouselTrigger = $('<img data-in-page-carousel-id="' + $carousel_id + '" class="carousel-image__trigger inpage-carousel-trigger" src="/app/themes/finegardening/dist/img/icon-plus.png" alt="" />');
          if ($this.hasClass('alignright')) {
            $this.removeClass('alignright');
            $this.parent().addClass('alignright');
          }
          if ($this.hasClass('aligncenter')) {
            $this.removeClass('aligncenter');
            $this.parent().addClass('aligncenter');
          }
          if ($this.hasClass('alignleft')) {
            $this.removeClass('alignleft');
            $this.parent().addClass('alignleft');
          }
          if ($this.height > 0) {
            $this.parent().css('max-height', $this.height);
          }
          if ($this.width > 0) {
            $this.parent().css('max-width', $(this).width);
          }

          $carouselTrigger.appendTo($this.parent());
          $this.parent().addClass('carousel-image__wrap inpage-carousel-trigger');
          $this.parent().attr('data-in-page-carousel-id', $carousel_id);

          const $slide = imageModal.modalSlide();
          var $caption = $this.closest('figure').find('figcaption');
          $slide.find(".modal__slide--left figure").attr('aria-labelledby', 'slide_' + $caption.attr('id'));
          $slide.find(".modal__slide--left figure img").attr('src', $img_href).attr('alt', $img_alt);
          $slide.find(".modal__slide--right figcaption").html($caption.html()).attr('id', 'slide_' + $caption.attr('id'));
          return $slide;
        },
        modalSlide: function () {
          return $('<div class="modal__slide"><div class="modal__slide--left"><figure><img loading="lazy" /></figure></div><div class="modal__slide--right"><figcaption></figcaption></div></div>').clone();
        },
      };

      if ($articleBodyCarousel.length < 1) {
        imageModal.init();
      } else {
        console.log('init main page carousel');
      }
    }

    if(typeof $.fn.owlCarousel === 'function') {
      // Modal Carousel - In Page
      $body.on('click', '.inpage-carousel-trigger', function () {
        const $this = $(this);
        const $modalCarouselWrap = $('.modal__wrapper--carousel');
        reveal($modalCarouselWrap);

        window.dataLayer.push({
          event: 'ui_interaction',
          action: 'view',
          section: 'carousel'
        });

        var $modalCarousel = $modalCarouselWrap.find('.owl-carousel'),
          $navPrevious = $modalCarouselWrap.find('.owl-prev'),
          $navNext = $modalCarouselWrap.find('.owl-next'),
          owl = $('.owl-carousel'),
          slider = $('.slider'),
          viewportHeight = $(window).height(),
          $modalImages = $modalCarousel.find('img');

        carouselModal.init();

        // Assign Max-Height to carousel images based on window height
        $modalImages.css('max-height', (viewportHeight - 175) + 'px');

        // Kick-off Owl Carousel
        $modalCarousel.css('display', 'block');
        $modalCarousel.owlCarousel({
          responsiveClass: true,
          responsive: {
            0: {
              items: 1
            }
          },
          nav: true,
          navText: ["prev", "next"]
        });


        if (typeof ($this.data('in-page-carousel-id')) != 'undefined') {
          owl.trigger('to.owl.carousel', [$this.data('in-page-carousel-id'), 0, false]);
        } else {
          owl.trigger('to.owl.carousel', [0]);
        }

        // Slideshow click handlers
        $modalCarouselWrap.on('click', '.owl-next', function () {
          $('.owl-item.active').data('slide-number');
        });

        $modalCarouselWrap.on('click', '.owl-prev', function () {
          $.publish('fg.slideshow.prev');
        });

        if (FG.page.pinterest) {
          $modalCarouselWrap.find('.owl-carousel').find('.owl-item').each(function () {
            $(this).find('img').parent().width($(this).find('img').width());
            $(this).find('img').parent().find('a').css('position', 'absolute');
            $(this).find('img').parent().find('a').css('bottom', '20px');
            $(this).find('img').parent().find('a').css('left', $(this).find('img').position().left + $(this).find('img').width() - 52);
          });
        }

      });
    }

    $articleModal.on('click', '.modal__close', function (e) {
      var $parentModal = $(e.target).closest('.modal__wrapper');
      vanish($parentModal);
    });

    $articleModal.on('transitionend', function (e) {
      var $parentModal = $(e.target).closest('.modal__wrapper');
      if (!$articleModal.hasClass('show')) {
        kill($parentModal);
      }
    });

  });

}
