import $ from 'jquery';
import reCaptchaValidate from './reCaptchaValidate';
import processEmailForm from './processEmailForm';


export default () => {

  $(function () {

    $('#newsletter-signup-splashpage').validate({
      onkeyup: false,
      onfocus: false,
      onsubmit: true,
      rules: {
				'email_sub_products[]': {
					required: true
				},
        email: {
          required: true,
          email: true
        }
      },
      messages: {
				'email_sub_products[]': {
					required: "Please select a Newsletter to sign up for"
				},
        email: {
          required: "Please enter your email",
          email: "Emails must contain @ and ."
        }
      },
			errorPlacement: function(error, element) {
				// Special case for checkboxes
				if (element.is(":checkbox")) {
						var $checksWrap = $('.checkbox_wrapper');
						$checksWrap.append(error);  // custom placement for checkboxes
				} else {
						error.insertAfter(element);   // default placement
				}
			},
      submitHandler: function(form){

        // Show the loading state
        $('.formfield__submit').parent().addClass('loading');

        function showThankyou() {
          $('.splash__form__top').hide(250);
          $('.splash__form').find('form').hide(250, function(){
            $('.splash__thank-you').show(250);
            $(window).scrollTop(150);
          });
        }

        reCaptchaValidate($(form).data('placement'))
            .then((result) => {
              processEmailForm(form)
              .then((result) => {
                showThankyou();
              })
              .catch((result) => {
                $(form).replaceWith('<p>Error Submitting Email</p>');
                console.log(result);
              });

            })
            .catch((result) => {
              $(form).replaceWith('<p>Spammer Detected!</p>');
              console.log(result);
            })
      }
    });

  });

}
