export default () => {

  const a = Math.floor((Math.random() * 10) + 1);
  console.log(a);

  if (a == 1 && pbjs !== 'undefined') {

    pbjs.onEvent('bidRequested', function(data) {
      //console.log('bidRequested', data);
      prebid_track('bid_request',data['bidderCode'], 1);
    });
    pbjs.onEvent('bidResponse', function(data) {
      //console.log('bidResponse', data);
      prebid_track('bid',data['bidder'], data['cpm']);
      prebid_track('response_time',data['bidder'], data['timeToRespond']);
    });
    pbjs.onEvent('bidTimeout', function(data) {
      //console.log('bidTimeout', data);
      for (var key in data) {
        //console.log('bidTimeout', key);
        prebid_track('bid_timeout',data[key]['bidder'], data[key]['timeout']);
      }
    });
    pbjs.onEvent('bidWon', function(data) {
      //console.log('bidWon',data);
      prebid_track('bid_won',data['bidder'], data['cpm']);
    });
    pbjs.onEvent('noBid', function(data) {
      //console.log('bidWon',data);
      prebid_track('no_bid',data['bidder'], 1);
    });
  }

  function prebid_track(event, bidder, value) {
    window.dataLayer.push({
      event: 'prebid_tracking',
      action: event,
      name: bidder,
      value: value
    });
  }

}
